import React, { Component, Fragment } from "react";
import Footer from "@components/global/footer/footer";
import { MyTicketsContainer } from "tf-checkout-react";
import moment from "moment";
import { setTfCheckoutReactConfigs } from "../utils/utils";
import "../styles/main.scss"
import { connect } from "react-redux";
import {
  toggleNav,
  createModal,
  removeModal,
} from "../actions/ui";
import LoginModal from "../containers/auth/login_modal";
import ForgotPasswordModal from "../containers/auth/forgot_password_modal";

setTfCheckoutReactConfigs();

class Orders extends Component {
  handleForgotPassword = () => {
    const { createModal } = this.props;

    const modal = {
      id: "forgot_password_modal",
      Component: () => <ForgotPasswordModal />,
      shouldCloseOnOverlayClick: true,
      isCloseBtn: true,
    };

    createModal(modal, true);
  };
  componentDidMount() {
    const {
      me: { exist },
    } = this.props.auth;
    if (!exist) {
      const { createModal, navOpen, toggleNav, browser, removeModal } =
        this.props;
      const isMobile = browser.lessThan.medium;

      const modal = {
        id: "login_modal",
        Component: () => (
          <div
            style={{
              maxWidth: isMobile ? "100%" : "480px",
              height: "inherit",
              display: "inline-flex",
              maxHeight: 550,
            }}
          >
            <LoginModal
              removeModal={removeModal}
              handleForgotPassword={this.handleForgotPassword}
            />
          </div>
        ),
        shouldCloseOnOverlayClick: false,
        isCloseBtn: true,
      };
      createModal(modal, true);

      if (navOpen) {
        toggleNav();
      }
    }
  }
  render() {
    const {
      me: { exist },
    } = this.props.auth;
    return (
      <Fragment>
        <div className="orders_container">
          <div className="container">
            {exist ? <MyTicketsContainer
              theme="light"
              selectEventsLabel="Select an Event"
              columns={[
                {
                  key: "date",
                  label: "Date",
                  normalizer: (value) => moment(value).format("MMMM DD, YYYY"),
                },
                {
                  key: "event",
                  label: "Event",
                  onCellClick: (cellData) => {
                    window.location.assign(
                      `/order/${cellData.id}`
                    );
                  },
                  component: (cellData) => (
                    <div className="event-info">{cellData.eventName}</div>
                  ),
                },
                { key: "total", label: "Total" },
              ]}
              handleDetailsInfo={(orderId) => {
                if (typeof window !== "undefined") {
                  window.location.assign(`/order/${orderId}`);
                }
              }}
            /> : null}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    navOpen: state.ui.navOpen,
    browser: state.browser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNav: () => {
      dispatch(toggleNav());
    },
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);

export const Head = () => <title>Restless Nites</title>;
